export const SENTIMENT_COLORS = {
    Negative: {
        end: 'rgba(180,0,0,0.9)',
        start: 'rgba(255,30,0,0.9)'
    },
    Neutral: {
        start: 'rgba(255,220,0,0.9)',
        mid: 'rgba(200,120,0,0.9)',
        end: 'rgba(255,220,0,0.9)'
    },
    Positive: {
        end: 'rgba(0,255,100,0.9)',
        start: 'rgba(0,150,20,0.9)'
    }
};
