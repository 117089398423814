import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { useStockPrice } from '../../../context/StockPriceContext';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const PriceChart = ({ symbol }) => {
    const { 
        priceData, 
        lastUpdated, 
        fetchPriceData 
    } = useStockPrice();

    // Get opening and current prices
    const openingPrice = priceData[0]?.price;
    const currentPrice = priceData[priceData.length - 1]?.price;
    
    // Determine line color based on price comparison
    const lineColor = openingPrice && currentPrice
        ? currentPrice >= openingPrice 
            ? 'rgb(75, 210, 143)'    // Lighter green (#4BD28F)
            : 'rgb(255, 105, 105)'   // Lighter red (#FF6969)
        : 'rgb(75, 192, 192)';       // Default color if no comparison possible

    useEffect(() => {
        if (symbol) {
            fetchPriceData(symbol);
            const pollInterval = setInterval(() => fetchPriceData(symbol), 60000);
            return () => clearInterval(pollInterval);
        }
    }, [symbol, fetchPriceData]);

    const chartData = {
        labels: priceData.map(d => new Date(d.timestamp).toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit' 
        })),
        datasets: [{
            label: 'Stock Price',
            data: priceData.map(d => d.price),
            borderColor: lineColor,
            borderWidth: 1.5,
            tension: 0.1,
            pointRadius: 0,
            pointHoverRadius: 3,
            fill: false
        }]
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: false },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function(context) {
                        return `₹${context.parsed.y.toFixed(2)}`;
                    }
                }
            }
        },
        hover: {
            mode: 'index',
            intersect: false
        },
        scales: {
            x: {
                grid: { display: false },
                ticks: {
                    autoSkip: false,
                    callback: function(value, index) {
                        const timestamp = priceData[index]?.timestamp;
                        if (!timestamp) return '';
                        
                        const date = new Date(timestamp);
                        const minutes = date.getMinutes();
                        // eslint-disable-next-line
                        const hours = date.getHours();
                        
                        // Only show labels for times at :00
                        if (minutes === 0) {
                            return date.toLocaleTimeString([], { 
                                hour: '2-digit', 
                                minute: '2-digit',
                                hour12: true 
                            });
                        }
                        return '';
                    }
                }
            },
            y: {
                grid: { color: 'rgba(0, 0, 0, 0.1)' },
                ticks: {
                    callback: function(value) {
                        return '₹' + value.toFixed(2);
                    }
                }
            }
        },
        animation: {
            duration: 0
        }
    };

    return (
        <div className="bg-white rounded-lg p-6 shadow-lg h-[350px]">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold">Price History</h3>
                {lastUpdated && (
                    <span className="text-xs text-gray-500">
                        Last updated: {lastUpdated.toLocaleTimeString()}
                    </span>
                )}
            </div>
            <div className="h-64">
                {priceData.length > 0 && (
                    <Line data={chartData} options={chartOptions} />
                )}
            </div>
        </div>
    );
};

export default PriceChart;
