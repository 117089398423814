import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { stockService } from '../../services/stock.service';
import { useAuth } from '../../context/AuthContext';

const StockList = () => {
    const [stocks, setStocks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { token } = useAuth();

    useEffect(() => {
        const fetchStocks = async () => {
            try {
                const data = await stockService.getAllStocks(token);
                setStocks(data);
            } catch (err) {
                setError('Failed to fetch stocks');
            } finally {
                setLoading(false);
            }
        };

        fetchStocks();
    }, [token]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="text-red-500">{error}</div>;

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Stock Analysis</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {stocks.map((stock) => (
                    <Link
                        key={stock.symbol}
                        to={`/stocks/${stock.name}`}
                        className="p-4 border rounded-lg hover:shadow-lg"
                    >
                        <h3 className="text-xl font-bold">{stock.name}</h3>
                        <p className="text-gray-600">{stock.symbol}</p>
                        <div className="mt-2">
                            <p>Open: ₹{stock.day_open}</p>
                            <p>Close: ₹{stock.day_close}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default StockList;