import React from 'react';
import { SENTIMENT_COLORS } from '../constants/sentimentColors';

const SentimentBar = ({ sentiments, calculateSentimentPercentages }) => {
    if (!sentiments?.length) {
        return (
            <div 
                className="w-full h-full rounded-md"
                style={{
                    background: `linear-gradient(90deg, 
                        ${SENTIMENT_COLORS.Neutral.start},
                        ${SENTIMENT_COLORS.Neutral.mid},
                        ${SENTIMENT_COLORS.Neutral.end}
                    )`
                }}
            />
        );
    }

    const percentages = calculateSentimentPercentages(sentiments);
    const hasNegative = percentages.Negative > 0;
    const hasPositive = percentages.Positive > 0;

    return (
        <div className="flex h-full w-full">
            {Object.entries(percentages)
                .sort(([sentimentA], [sentimentB]) => {
                    const order = { Positive: 0, Neutral: 1, Negative: 2 };
                    return order[sentimentA] - order[sentimentB];
                })
                .filter(([, percentage]) => percentage > 0)
                .map(([sentiment, percentage], index, array) => {
                    let gradientColors;
                    if (sentiment === 'Neutral') {
                        if (!hasNegative && hasPositive) {
                            gradientColors = `${SENTIMENT_COLORS.Neutral.start}, ${SENTIMENT_COLORS.Neutral.mid}`;
                        } else if (hasNegative && !hasPositive) {
                            gradientColors = `${SENTIMENT_COLORS.Neutral.mid}, ${SENTIMENT_COLORS.Neutral.end}`;
                        } else {
                            gradientColors = `${SENTIMENT_COLORS.Neutral.start}, ${SENTIMENT_COLORS.Neutral.mid}, ${SENTIMENT_COLORS.Neutral.end}`;
                        }
                    } else {
                        gradientColors = `${SENTIMENT_COLORS[sentiment].start}, ${SENTIMENT_COLORS[sentiment].end}`;
                    }

                    return (
                        <div
                            key={sentiment}
                            style={{
                                width: `${percentage}%`,
                                background: `linear-gradient(90deg, ${gradientColors})`
                            }}
                            className="h-full first:rounded-l-md last:rounded-r-md relative cursor-pointer"
                            onMouseEnter={(e) => {
                                const tooltip = document.createElement('div');
                                tooltip.className = 'fixed bg-black text-white px-2 py-1 text-xs rounded z-50';
                                tooltip.textContent = `${sentiment}: ${percentage.toFixed(1)}%`;
                                tooltip.style.top = `${e.clientY - 30}px`;
                                tooltip.style.left = `${e.clientX}px`;
                                tooltip.id = 'sentiment-tooltip';
                                document.body.appendChild(tooltip);
                            }}
                            onMouseMove={(e) => {
                                const tooltip = document.getElementById('sentiment-tooltip');
                                if (tooltip) {
                                    tooltip.style.top = `${e.clientY - 30}px`;
                                    tooltip.style.left = `${e.clientX}px`;
                                }
                            }}
                            onMouseLeave={() => {
                                const tooltip = document.getElementById('sentiment-tooltip');
                                if (tooltip) {
                                    document.body.removeChild(tooltip);
                                }
                            }}
                        />
                    );
                })}
        </div>
    );
};

export default SentimentBar; 