import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import topleft from '../assets/topleft.png';

const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <nav className="bg-gray-100 border-b border-gray-100">
      <div className="container mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          <Link to="/dashboard" className="flex items-center gap-4 ml-16">
            <img 
              src={topleft} 
              alt="Company Logo" 
              className="h-8 w-8"
            />
          </Link>

          <div className="flex items-center space-x-6">
            {user ? (
              <>
                <Link 
                  to="/stocks" 
                  className="text-gray-600 hover:text-gray-800 text-sm"
                >
                  Stocks
                </Link>
                <span className="text-gray-600 text-sm">{user}</span>
                <button
                  onClick={handleLogout}
                  className="px-4 py-2 bg-emerald-500 text-white text-sm rounded hover:bg-emerald-600 transition-colors"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="text-gray-600 hover:text-gray-800 text-sm"
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="px-4 py-2 bg-emerald-500 text-white text-sm rounded hover:bg-emerald-600 transition-colors"
                >
                  Create Account
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;