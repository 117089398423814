import React, { createContext, useContext, useState, useCallback, useRef } from 'react';
import { useAuth } from './AuthContext';
import { stockService } from '../services/stock.service';

const StockPriceContext = createContext(null);

export const StockPriceProvider = ({ children }) => {
    const [priceData, setPriceData] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [isLoadingPrice, setIsLoadingPrice] = useState(true);
    const [priceError, setPriceError] = useState(null);
    const { token } = useAuth();
    
    // Use ref to track last fetch time
    const lastFetchTime = useRef(0);

    const fetchPriceData = useCallback(async (symbol) => {
        const now = Date.now();
        // Only fetch if more than 1 second has passed since last fetch
        if (now - lastFetchTime.current >= 1000) {
            try {
                setIsLoadingPrice(true);
                setPriceError(null);

                const data = await stockService.getStockPriceHistory(symbol, token);
                setPriceData(data);
                setLastUpdated(new Date());
                lastFetchTime.current = now;

            } catch (err) {
                console.error('Price fetch error:', err);
                setPriceError(err.message || 'Failed to load price data');
            } finally {
                setIsLoadingPrice(false);
            }
        }
    }, [token]);

    return (
        <StockPriceContext.Provider value={{
            priceData,
            lastUpdated,
            isLoadingPrice,
            priceError,
            fetchPriceData
        }}>
            {children}
        </StockPriceContext.Provider>
    );
};

export const useStockPrice = () => useContext(StockPriceContext);
