import React from 'react';
import SentimentBar from './SentimentBar';
import { SENTIMENT_COLORS } from '../constants/sentimentColors';

const SentimentOverview = ({ stockData }) => {
    const getSentimentEmoji = (sentiment) => {
        const lowerSentiment = sentiment?.toLowerCase() || '';
        if (lowerSentiment.includes('positive')) return '😊';
        if (lowerSentiment.includes('negative')) return '☹️';
        return '😐';
    };

    const getSentimentColor = (sentiment) => {
        const lowerSentiment = sentiment?.toLowerCase() || '';
        if (lowerSentiment.includes('positive')) return { color: SENTIMENT_COLORS.Positive.start };
        if (lowerSentiment.includes('negative')) return { color: SENTIMENT_COLORS.Negative.start };
        return { color: SENTIMENT_COLORS.Neutral.start };
    };

    const calculateSentimentPercentages = (sentiments) => {
        const counts = {
            Positive: 0,
            Neutral: 0,
            Negative: 0
        };
        
        if (sentiments) {
            sentiments.forEach(sentiment => {
                if (sentiment in counts) {
                    counts[sentiment] += 1;
                }
            });
        }
        
        const total = Object.values(counts).reduce((a, b) => a + b, 0);
        if (total === 0) return counts;
        
        return Object.fromEntries(
            Object.entries(counts).map(([k, v]) => [k, (v / total) * 100])
        );
    };

    const calculateSentiment = (sentiments) => {
        if (!sentiments || sentiments.length === 0) return null;
        
        const counts = {
            Positive: 0,
            Neutral: 0,
            Negative: 0
        };
        
        sentiments.forEach(sentiment => {
            if (sentiment in counts) {
                counts[sentiment] += 1;
            }
        });
        
        // Get all sentiments with counts > 0
        const nonZeroSentiments = Object.entries(counts)
            .filter(([, count]) => count > 0);
            
        // If only two sentiments with equal counts and one is neutral
        if (nonZeroSentiments.length === 2) {
            const [first, second] = nonZeroSentiments;
            if (first[1] === second[1]) {
                if (first[0] === 'Neutral') return { text: second[0], score: second[1] };
                if (second[0] === 'Neutral') return { text: first[0], score: first[1] };
            }
        }
        
        // Find max count
        const maxCount = Math.max(...Object.values(counts));
        
        // Get all sentiments with max count
        const maxSentiments = Object.entries(counts)
            .filter(([, count]) => count === maxCount)
            .map(([sentiment]) => sentiment);
        
        // If multiple sentiments have equal max count, return Neutral
        if (maxSentiments.length > 1) {
            return { text: 'Neutral', score: counts['Neutral'] };
        }
        
        // Otherwise return the sentiment with max count
        return {
            text: maxSentiments[0],
            score: maxCount
        };
    };

    const overallSentiment = stockData?.article_sentiments?.length > 0 
        ? calculateSentiment(stockData.article_sentiments)
        : { text: stockData?.overall_sentiment, score: null };

    const getSentimentCounts = (sentiments) => {
        const counts = {
            Positive: 0,
            Neutral: 0,
            Negative: 0
        };
        
        if (sentiments) {
            sentiments.forEach(sentiment => {
                if (sentiment in counts) {
                    counts[sentiment] += 1;
                }
            });
        }
        
        return counts;
    };

    const sentimentCounts = getSentimentCounts(stockData.article_sentiments);

    return (
        <div className="col-span-1">
            <div className="bg-white rounded-lg p-6 shadow-lg mb-6">
                <h3 className="text-lg font-bold mb-4 text-center">Current Sentiment</h3>
                <div className="flex flex-col items-center gap-4">
                    <span className="text-7xl mb-3">
                        {getSentimentEmoji(overallSentiment?.text)}
                    </span>
                    
                    <div className="w-3/4 h-7 rounded-md overflow-hidden relative">
                        <SentimentBar 
                            sentiments={stockData.article_sentiments}
                            calculateSentimentPercentages={calculateSentimentPercentages}
                        />
                    </div>

                    <div className="text-center mt-1">
                        <div className="text-lg font-bold" style={getSentimentColor(overallSentiment?.text)}>
                            {overallSentiment?.text}
                        </div>
                        <div className="text-sm text-gray-500">Dominant sentiment</div>
                    </div>

                    <div className="w-full mt-2 pt-3 border-t border-gray-200">
                        <h4 className="text-sm font-semibold text-gray-700 mb-3 text-center">Article Sentiments</h4>
                        <div className="grid grid-cols-3 gap-2 text-center">
                            <div>
                                <div className="text-lg font-semibold" style={{ color: SENTIMENT_COLORS.Positive.start }}>
                                    {sentimentCounts.Positive}
                                </div>
                                <div className="text-xs text-gray-500">Positive</div>
                            </div>
                            <div>
                                <div className="text-lg font-semibold" style={{ color: SENTIMENT_COLORS.Neutral.start }}>
                                    {sentimentCounts.Neutral}
                                </div>
                                <div className="text-xs text-gray-500">Neutral</div>
                            </div>
                            <div>
                                <div className="text-lg font-semibold" style={{ color: SENTIMENT_COLORS.Negative.start }}>
                                    {sentimentCounts.Negative}
                                </div>
                                <div className="text-xs text-gray-500">Negative</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SentimentOverview;