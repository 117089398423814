import React from 'react';
import PriceChart from '../../Charts/PriceChart';
import SourcesPieChart from './SourcesPieChart';
import NewsArticles from './NewsArticles';

const DetailedAnalysis = ({ showDetails, setShowDetails, stockData }) => {
    return (
        <div>
            <button 
                onClick={() => setShowDetails(!showDetails)}
                className="flex items-center gap-2 py-4 text-left"
            >
                <span className="font-bold text-lg">More Details</span>
                <svg 
                    className={`w-5 h-5 transform transition-transform ${showDetails ? 'rotate-180' : ''}`}
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
            </button>

            <div className={`transition-all duration-300 ease-in-out ${showDetails ? 'opacity-100 max-h-[1000px]' : 'opacity-0 max-h-0 overflow-hidden'}`}>
                {showDetails && (
                    <>
                        <hr className="border-t border-gray-200 mb-6" />
                        <div className="grid grid-cols-3 gap-6">
                            <PriceChart symbol={stockData.symbol} />
                            <SourcesPieChart stockData={stockData} />
                            <NewsArticles stockData={stockData} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DetailedAnalysis; 