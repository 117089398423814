import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { authService } from '../../services/auth.service';
import { useAuth } from '../../context/AuthContext';
import bottom from '../assets/bottom.png';
import topleft from '../assets/topleft.png';
import graph from '../assets/Graph.png';

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { setShowNavbar } = useAuth();

    useEffect(() => {
        setShowNavbar(false);
        return () => setShowNavbar(true);
    }, [setShowNavbar]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            await authService.register(username, password);
            navigate('/login');
        } catch (err) {
            setError(err.message || 'Registration failed');
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <div className="flex flex-grow">
                {/* Left Section */}
                <div className="w-2/3 bg-gray-100 p-8 flex flex-col">
                    {/* Top Logo */}
                    <div className="mb-8 ml-16">
                        <div className="flex items-center gap-4">
                            <img 
                                src={topleft}
                                alt="Company Logo" 
                                className="h-8 w-8"
                            />
                        </div>
                    </div>

                    {/* Center Content */}
                    <div className="flex-grow flex flex-col justify-center items-center">
                        {/* Graph Image */}
                        <div className="w-full max-w-md mb-6">
                            <div className="relative h-80 w-full">
                                <img 
                                    src={graph}
                                    alt="Stock Graph" 
                                    className="w-full h-full object-contain"
                                />
                            </div>
                        </div>
                        
                        <h1 className="text-3xl text-gray-800 font-bold text-left max-w-2xl mb-2 ml-24">
                            Build Your Bias with Confidence
                        </h1>
                        <p className="text-2xl text-gray-600 text-left max-w-md mb-12 mr-36">
                            Get a free account!
                        </p>
                    </div>
                </div>

                {/* Right Section */}
                <div className="w-1/3 bg-white p-8 flex flex-col">
                    {/* Top Navigation */}
                    <div className="flex justify-center md:justify-start md:pl-[calc(6rem+1rem)] items-center space-x-4 mb-12 -mx-8 -mt-8 bg-gray-100 p-8">
                        <Link 
                            to="/login" 
                            className="text-sm text-emerald-500 border border-emerald-500 px-10 py-2 rounded-lg hover:bg-emerald-50 text-base bg-white"
                        >
                            Login
                        </Link>
                        <button 
                            className="px-6 py-2 bg-[#00B386] text-white text-base rounded-lg hover:bg-[#009973] transition-all shadow-sm"
                        >
                            Create Account
                        </button>
                    </div>

                    <div className="flex-grow flex items-center justify-center">
                        <div className="w-full max-w-md">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                {error && (
                                    <div className="p-4 bg-red-50 border border-red-200 text-red-600 rounded">
                                        {error}
                                    </div>
                                )}
                                <div>
                                    <label className="block text-sm font-bold text-black mb-2">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        className="w-full px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                                        placeholder="Enter your email"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-bold text-black mb-2">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="w-full px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                                        placeholder="Enter your password"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-bold text-black mb-2">
                                        Re-Enter Password
                                    </label>
                                    <input
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className="w-full px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                                        placeholder="Confirm your password"
                                        required
                                    />
                                </div>

                                <button
                                    type="submit"
                                    className="w-full bg-emerald-500 text-white py-2 px-4 rounded-lg hover:bg-emerald-600 transition-colors"
                                >
                                    Create Account
                                </button>

                                <div className="relative my-6">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="w-full border-t border-gray-300"></div>
                                    </div>
                                    <div className="relative flex justify-center text-sm">
                                        <span className="px-2 bg-white text-gray-500">or</span>
                                    </div>
                                </div>

                                <div className="flex justify-center">
                                    <p className="text-sm text-gray-600 -mt-4">
                                        Already have an account?{' '}
                                        <Link 
                                            to="/login" 
                                            className="text-emerald-500 hover:text-emerald-600"
                                        >
                                            Login
                                        </Link>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bottom Section - Full Width */}
            <div className="w-full bg-white -mt-12">
                <div className="container mx-auto px-8 py-4">
                    <div className="flex flex-col items-start gap-1">
                        <div className="flex items-start gap-3 ml-16">
                            <img 
                                src={bottom}
                                alt="Company Logo" 
                                className="h-12 w-32"
                            />
                        </div>
                        <p className="text-sm text-gray-600 ml-16">
                            Stay ahead with a holistic view of stock sentiment. Access curated, <br/>realtime news sentiment on top Indian stocks to make informed decisions.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;