import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import { stockService } from '../../services/stock.service';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import bottom from '../assets/bottom.png';
import topcenter from '../assets/topcenter.png';

const StockDashboard = () => {
  const { token } = useAuth();
  const [stocks, setStocks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredStocks, setFilteredStocks] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [stocksData, userCompanies] = await Promise.all([
          stockService.getAllStocks(token),
          stockService.getUserCompanies(token)
        ]);
        
        setStocks(stocksData);
        const userStocks = stocksData.filter(stock => 
          userCompanies.companies.includes(stock.name)
        );
        setSelectedStocks(userStocks);
      } catch (err) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = stocks.filter(stock =>
        stock.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredStocks(filtered);
    } else {
      setFilteredStocks([]);
    }
  }, [searchTerm, stocks]);

  const handleSelectStock = async (stock) => {
    try {
      if (selectedStocks.length >= 5) {
        setError('You can only add up to 5 companies');
        setSearchTerm('');
        setFilteredStocks([]);
        setTimeout(() => setError(''), 10000);
        return;
      }

      if (selectedStocks.some(s => s.name === stock.name)) {
        setError('This stock is already in your list');
        setSearchTerm('');
        setFilteredStocks([]);
        setTimeout(() => setError(''), 10000);
        return;
      }

      await stockService.addCompany(stock.name, token);
      setSelectedStocks([...selectedStocks, stock]);
      setSearchTerm('');
      setFilteredStocks([]);
    } catch (err) {
      setError('Failed to add stock');
      setSearchTerm('');
      setFilteredStocks([]);
      setTimeout(() => setError(''), 10000);
    }
  };

  // const handleRemoveStock = async (stock) => {
  //   try {
  //     await stockService.removeCompany(stock.name, token);
  //     setSelectedStocks(selectedStocks.filter(s => s.name !== stock.name));
  //   } catch (err) {
  //     setError('Failed to remove stock');
  //   }
  // };

  const handleStockDetail = (stock) => {
    navigate(`/stocks/${stock.name}`);
  };

  const handleJoinWaitlist = () => {
    console.log('Joining waitlist...');
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="h-screen bg-gray-100 flex flex-col justify-between">
      <div>
        <main className="max-w-2xl mx-auto p-4">
          <div className="flex flex-col items-center">
            <img 
              src={topcenter}
              alt="Build A Bias Logo" 
              className="h-36 w-auto mb-4"
            />
            <p className="font-bold text-black text-xl mb-12">Stock analysis and screening tool for investors in India</p>
          </div>

          <div className="relative mb-6 max-w-[75%] mx-auto">
            {selectedStocks.length >= 5 ? (
              <button
                onClick={handleJoinWaitlist}
                className="w-full px-10 py-2 bg-[#00B386] text-white text-base rounded-lg hover:bg-[#009973] transition-all shadow-sm"
              >
                Join Waitlist to Track More Stocks
              </button>
            ) : (
              <>
                <Search className="absolute left-3 top-3 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search for a stock"
                  className="w-full p-3 pl-10 border rounded-lg shadow-sm"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {error && (
                  <p className="text-red-500 text-sm mt-2">{error}</p>
                )}
                {filteredStocks.length > 0 && (
                  <div className="absolute z-10 bg-white border rounded-lg shadow-lg mt-1 w-full">
                    {filteredStocks.map((stock) => (
                      <div
                        key={stock.name}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleSelectStock(stock)}
                      >
                        {stock.name}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="bg-white rounded-lg shadow p-4">
            <div className="mb-4">
              <div className="flex items-center">
                <h2 className="font-semibold">My Stocks</h2>
                <span className="ml-2 text-lg text-red-500">({selectedStocks.length}/5)</span>
              </div>
            </div>
            <table className="min-w-full border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-2 text-left">Stock Name</th>
                  <th className="p-2 text-left">Day Open</th>
                  <th className="p-2 text-left">Day Close</th>
                  {/* <th className="p-2 text-left"></th> */}
                </tr>
              </thead>
              <tbody>
                {selectedStocks.map((stock) => (
                  <tr key={stock.name} className="hover:bg-gray-100">
                    <td 
                      className="p-2 border-b cursor-pointer"
                      onClick={() => handleStockDetail(stock)}
                    >
                      {stock.name}
                    </td>
                    <td className="p-2 border-b">₹{stock.day_open.toFixed(2)}</td>
                    <td className="p-2 border-b">₹{stock.day_close.toFixed(2)}</td>
                    {/* <td className="p-2 border-b">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveStock(stock);
                        }}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        
        </main>
      </div>

      <div className="w-full bg-white mt-10">
        <div className="container mx-auto px-8 py-4">
          <div className="flex flex-col items-start gap-1">
            <div className="flex items-start gap-3 ml-16">
              <img 
                src={bottom}
                alt="Company Logo" 
                className="h-12 w-32"
              />
            </div>
            <p className="text-sm text-gray-600 ml-16">
              Stay ahead with a holistic view of stock sentiment. Access curated, <br/>realtime news sentiment on top Indian stocks to make informed decisions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockDashboard;