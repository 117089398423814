import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { stockService } from '../../services/stock.service';
import { useAuth } from '../../context/AuthContext';
import CompanyInfo from './components/CompanyInfo';
import SentimentOverview from './components/SentimentOverview';
import DetailedAnalysis from './components/DetailedAnalysis';
import bottom from '../assets/bottom.png';

const StockDetail = () => {
    const { companyName } = useParams();
    const [stockData, setStockData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { token } = useAuth();
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        const fetchStockDetails = async () => {
            try {
                const data = await stockService.getStockByCompany(companyName, token);
                setStockData(data[0]);
            } catch (err) {
                setError('Failed to fetch stock details');
            } finally {
                setLoading(false);
            }
        };

        fetchStockDetails();
    }, [companyName, token]);

    if (loading) return <div className="text-center p-4">Loading...</div>;
    if (error) return <div className="text-red-500 text-center p-4">{error}</div>;
    if (!stockData) return <div className="text-center p-4">No data found</div>;

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-between">
            <div className="container mx-auto p-8 max-w-[1400px]">
                <div className="bg-white rounded-lg shadow p-6">
                    <div className="grid grid-cols-4 gap-6">
                        <CompanyInfo stockData={stockData} />
                        <SentimentOverview stockData={stockData} />
                    </div>
                </div>
                
                <DetailedAnalysis 
                    showDetails={showDetails}
                    setShowDetails={setShowDetails}
                    stockData={stockData}
                />
            </div>

            {/* Bottom Section - Full Width */}
            <div className="w-full bg-white mt-10">
                <div className="container mx-auto px-8 py-4">
                    <div className="flex flex-col items-start gap-1">
                        <div className="flex items-start gap-3 ml-16">
                            <img 
                                src={bottom}
                                alt="Company Logo" 
                                className="h-12 w-32"
                            />
                        </div>
                        <p className="text-sm text-gray-600 ml-16">
                            Stay ahead with a holistic view of stock sentiment. Access curated, <br/>realtime news sentiment on top Indian stocks to make informed decisions.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StockDetail;