import React from 'react';
import DayPrices from './DayPrices';
import MovementReasons from './MovementReasons';

const CompanyInfo = ({ stockData }) => {
    return (
        <div className="col-span-3">
            <div className="flex items-center gap-4 mb-6">
                <h2 className="text-2xl font-bold">{stockData.name}</h2>
                <span className="text-gray-600">({stockData.symbol})</span>
                <span className="text-sm text-gray-500">{stockData.exchange}</span>
            </div>
            
            <div className="text-sm text-gray-700 mb-6">
                {stockData.overall_summary}
            </div>

            <DayPrices stockData={stockData} />
            <MovementReasons stockData={stockData} />
        </div>
    );
};

export default CompanyInfo; 