import React from 'react';

const DayPrices = ({ stockData }) => {
    const priceData = [
        { label: 'Day High', value: stockData.day_high },
        { label: 'Day Low', value: stockData.day_low },
        { label: 'Day Open', value: stockData.day_open },
        { label: 'Day Close', value: stockData.day_close },
    ];

    return (
        <div className="grid grid-cols-4 gap-4 mb-6">
            {priceData.map(({ label, value }) => (
                <div key={label} className="bg-white rounded-lg p-3 shadow-lg">
                    <div className="text-gray-500">{label}</div>
                    <div className="font-bold">₹{Number(value).toFixed(2)}</div>
                </div>
            ))}
        </div>
    );
};

export default DayPrices; 