import React from 'react';

const MovementReasons = ({ stockData }) => {
    return (
        <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="bg-white rounded-lg p-4 shadow-lg">
                <h4 className="text-green-600 font-semibold mb-3">Top Reasons for Positive Sentiment</h4>
                <ul className="list-disc list-inside space-y-2 text-sm">
                    {stockData.top_reasons_for_positive_movement?.map((reason, index) => (
                        <li key={`positive-${index}`} className="text-gray-700">{reason}</li>
                    ))}
                </ul>
            </div>
            <div className="bg-white rounded-lg p-4 shadow-lg">
                <h4 className="text-red-600 font-semibold mb-3">Top Reasons for Negative Sentiment</h4>
                <ul className="list-disc list-inside space-y-2 text-sm">
                    {stockData.top_reasons_for_negative_movement?.map((reason, index) => (
                        <li key={`negative-${index}`} className="text-gray-700">{reason}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default MovementReasons; 