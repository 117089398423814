const BASE_URL = process.env.REACT_APP_API_URL;

export const stockService = {
    getAllStocks: async (token) => {
        const response = await fetch(`${BASE_URL}/api/stock-analysis`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (!response.ok) {
            throw new Error('Failed to fetch stocks');
        }
        
        return await response.json();
    },

    getStockByCompany: async (companyName, token) => {
        const response = await fetch(`${BASE_URL}/api/stock-analysis/${companyName}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (!response.ok) {
            throw new Error('Failed to fetch stock details');
        }
        
        return await response.json();
    },

    addCompany: async (companyName, token) => {
        const response = await fetch(`${BASE_URL}/companies/add?company=${encodeURIComponent(companyName)}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (!response.ok) {
            throw new Error('Failed to add company');
        }
        
        return await response.json();
    },

    removeCompany: async (companyName, token) => {
        const response = await fetch(`${BASE_URL}/companies/remove?company=${encodeURIComponent(companyName)}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (!response.ok) {
            throw new Error('Failed to remove company');
        }
        
        return await response.json();
    },

    getUserCompanies: async (token) => {
        const response = await fetch(`${BASE_URL}/companies`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (!response.ok) {
            throw new Error('Failed to fetch user companies');
        }
        
        return await response.json();
    },

    getStockPriceHistory: async (symbol, token) => {
        const response = await fetch(`${BASE_URL}/api/stock-price/${symbol}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || 'Failed to fetch price history');
        }
        
        return await response.json();
    }
};