import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

// Register ChartJS components
ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

const SourcesPieChart = ({ stockData }) => {
    const extractSource = (url) => {
        try {
            const cleanUrl = url.startsWith('@') ? url.substring(1) : url;
            const domain = cleanUrl.split('//')[1].split('/')[0];
            return domain;
        } catch (error) {
            return 'unknown';
        }
    };

    const calculateSourceDistribution = (urls) => {
        const sources = urls.map(extractSource);
        const distribution = sources.reduce((acc, source) => {
            acc[source] = (acc[source] || 0) + 1;
            return acc;
        }, {});

        return Object.entries(distribution).map(([source, count]) => ({
            source,
            count,
            percentage: (count / urls.length) * 100
        }));
    };

    const generateRandomColors = (count) => {
        const colors = [];
        const baseColors = [
            { start: 'rgba(180,0,0,0.9)', end: 'rgba(255,30,0,0.9)' },      // Red metallic
            { start: 'rgba(0,255,100,0.9)', end: 'rgba(0,150,20,0.9)' },    // Green metallic
            { start: 'rgba(255,220,0,0.9)', end: 'rgba(200,120,0,0.9)' },   // Gold metallic
            { start: 'rgba(0,150,255,0.9)', end: 'rgba(0,50,150,0.9)' },    // Blue metallic
            { start: 'rgba(180,0,255,0.9)', end: 'rgba(100,0,150,0.9)' },   // Purple metallic
        ];

        for (let i = 0; i < count; i++) {
            const colorPair = baseColors[i % baseColors.length];
            colors.push(i % 2 === 0 ? colorPair.start : colorPair.end);
        }
        return colors;
    };

    const preparePieChartData = (urls) => {
        const distribution = calculateSourceDistribution(urls);
        const colors = generateRandomColors(distribution.length);

        return {
            labels: distribution.map(item => item.source),
            datasets: [{
                data: distribution.map(item => item.percentage),
                backgroundColor: colors,
                borderColor: colors.map(color => color.replace('0.6', '1')),
                borderWidth: 1,
                articleCounts: distribution.map(item => item.count)
            }]
        };
    };

    const pieOptions = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const count = context.dataset.articleCounts[context.dataIndex];
                        return `${count} articles`;
                    }
                }
            },
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 15,
                    padding: 15,
                    font: {
                        size: 12
                    }
                }
            }
        },
        maintainAspectRatio: false
    };

    return (
        <div className="bg-white rounded-lg p-6 shadow-lg h-[350px]">
            <h3 className="text-lg font-bold mb-4">Sources</h3>
            <div className="h-64">
                {stockData.article_urls.length > 0 ? (
                    <Pie 
                        data={preparePieChartData(stockData.article_urls)}
                        options={pieOptions}
                    />
                ) : (
                    <div className="h-full flex items-center justify-center text-gray-500">
                        No source data available
                    </div>
                )}
            </div>
        </div>
    );
};

export default SourcesPieChart; 